import React from 'react'
import { graphql, Link } from 'gatsby'
import { PortableText } from '@portabletext/react'

import StyledGeneralPage from '../components/styles/StyledGeneralPage'
import Seo from '../components/Seo'

const PrivacyPolicyPage = ({ data }) => {
  const {
    title,
    _rawContent
  } = data.sanityPage

  return (
    <StyledGeneralPage className="page-container-xl">
      <Seo title={title} />
      <h1>{title}</h1>

      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        {title}
      </p>

      <PortableText
        value={_rawContent}
      />
    </StyledGeneralPage>
  )
}

export const query = graphql`
  query PrivacyPolicyPageQuery {
    sanityPage(slug: {current: {eq: "privacy-policy"}}) {
      title
      _rawContent
    }
  }
`

export default PrivacyPolicyPage